import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { LoadingPage, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { tablet, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import Checklist from './components/Checklist';
import { normalizeStates } from 'utils/localization';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 2rem 1rem 3.5rem;
  }
`;

const Title = styled(Text)`
  color: #2fbe8f;
  text-align: center;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.026rem;
  margin-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.024rem;
  }
`;

const ImageWrapper = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
  svg {
    width: 11.25rem;
    height: 11.25rem;
  }
`;

const StyledPercent = styled(Text)`
  position: absolute;
  color: #2fbe8f;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const LOADING_DURATION = 7500;
const INTERVAL = 75;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const data = useQuizData('calculating');
  const { config, user } = useSelector((state: AppState) => state);
  const variant = config?.variant;

  const { goToResults, goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user?.user?.email,
  });

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [window.location.href]);

  useEffect(() => {
    let tickCount = 0;
    const totalTicks = LOADING_DURATION / INTERVAL;

    // Ensure progress starts at 0
    setProgress(0);

    const animationInterval = setInterval(() => {
      tickCount += 1;
      const newProgress = Math.min((tickCount / totalTicks) * 100, 100);
      setProgress(newProgress);

      if (tickCount >= totalTicks) {
        clearInterval(animationInterval);
        setIsLoading(false);
      }
    }, INTERVAL);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const completeTimer = setTimeout(() => goToEmail(), 1000);

      return () => clearTimeout(completeTimer);
    }
  }, [isLoading]);

  const handleNavigationOnCounterEnd = () => {
    // if (variant === 'funnel-email' || variant === 'email-first') {
    //   return location.search.includes('tst3') ? goToResults() : goToEmail();
    // }
    // goToResults();
  };

  if (!data) {
    return <LoadingPage />;
  }

  const {
    title,
    progressTitle,
    imageUrl,
    list,
    modalQuestion,
    modalAnswer1,
    modalAnswer2,
    showModal,
    chart,
  } = data;

  return (
    <>
      <Seo />

      <Header />
      <ContentContainer>
        <div>
          <ImageWrapper>
            <CircularProgressbar
              value={progress}
              text=""
              styles={buildStyles({
                pathTransitionDuration: 0.25,
                pathColor: '#2FBE8F',
                trailColor: '#FBFBFB',
                strokeLinecap: 'round',
              })}
            />
            <StyledPercent>{Math.round(progress)}%</StyledPercent>
          </ImageWrapper>
        </div>
        <Title>{title}</Title>
        {/* <LoadingBar progress={progress} />
          <Analyzing>{progressTitle}</Analyzing> */}
        <Checklist
          list={list}
          onEnd={handleNavigationOnCounterEnd}
          onProgress={progress => setProgress(progress)}
          modalQuestion={modalQuestion}
          modalAnswer1={modalAnswer1}
          modalAnswer2={modalAnswer2}
          showModal={showModal}
        />
      </ContentContainer>
    </>
  );
};

export default Calculating;
