import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import theme from 'utils/theme';
import CircleTick from 'assets/icons/checkmark.svg';
import CalculatingModal from './CalculatingModal';

interface ChecklistProps {
  list?: string[];
  onEnd: () => void;
  onProgress: (progress: number) => void;
  modalQuestion: string;
  modalAnswer1: string;
  modalAnswer2: string;
  showModal?: boolean;
}

interface ChecklistItem {
  title: string;
  opacity: number;
  delay: number;
}

const ListContainer = styled.div``;

const ListItemContainer = styled.div<{ opacity: number; delay: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  transition: opacity 0.5s ease;
  transition-delay: ${({ delay }) => delay}s;
  opacity: ${({ opacity }) => opacity};
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const ListTitle = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.016rem;
  padding-left: 0.5rem;
`;

const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const Checklist: FC<ChecklistProps> = ({
  list,
  onEnd,
  onProgress,
  modalQuestion,
  modalAnswer1,
  modalAnswer2,
  showModal,
}) => {
  const [firstHalfList, setFirstHalfList] = useState<ChecklistItem[]>(
    list?.map((title, index) => ({ title, opacity: 0, delay: index * 1.5 })) ||
      [],
  );
  // const [secondHalfList, setSecondHalfList] = useState<ChecklistItem[]>(
  //   list
  //     ?.slice()
  //     .splice(-middleIndex)
  //     .map((title, index) => ({ title, opacity: 0, delay: index * 1.5 })) || [],
  // );
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleFirstHalfList = async () => {
    await timeout(50);
    // onProgress(100);
    setFirstHalfList(prev => {
      const newList = prev.map(p => ({
        ...p,
        opacity: 1,
      }));
      return newList;
    });

    await timeout(3000);
    // if (showModal) {
    //   return handleOpenModal();
    // }
    // handleModalButtonClick();
  };

  // const handleSecondHalfList = async () => {
  //   onProgress(100);
  //   setSecondHalfList(prev => {
  //     const newList = prev.map(p => ({
  //       ...p,
  //       opacity: 1,
  //     }));
  //     return newList;
  //   });
  //   await timeout(5000);
  //   onEnd();
  // };

  // const handleOpenModal = () => setIsModalOpen(true);
  // const handleCloseModal = () => setIsModalOpen(false);

  // const handleModalButtonClick = () => {
  //   handleCloseModal();
  //   // handleSecondHalfList();
  // };

  useEffect(() => {
    handleFirstHalfList();
  }, []);

  const renderBulletpoints = (
    { title, opacity, delay }: ChecklistItem,
    index: number,
  ) => (
    <ListItemContainer opacity={opacity} delay={delay} key={index}>
      <CircleTick width={20} height={20} />
      <ListTitle>{title}</ListTitle>
    </ListItemContainer>
  );

  return (
    <ListContainer>
      {firstHalfList.map(renderBulletpoints)}
      {/* {secondHalfList.map(renderBulletpoints)}
      <CalculatingModal
        isOpen={isModalOpen}
        onClose={handleModalButtonClick}
        modalQuestion={modalQuestion}
        modalAnswer1={modalAnswer1}
        modalAnswer2={modalAnswer2}
      /> */}
    </ListContainer>
  );
};

export default Checklist;
